import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { COMMON_QUERY_KEYS } from 'src/constants/query';
import {
  IDepartment,
  IPermission,
  IRole,
  IUser,
  Lead,
} from 'src/interfaces/models';
import { Dashboard, DashboardGoals } from 'src/interfaces/models/Dashboard';
import apiAxios from 'src/services/api/axios';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

/** MODULES */
export async function getModules(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<Record<string, boolean>[]>(
    '/admin/common/modules',
    config,
  );

  return response;
}

/** DASHBOARD */
export async function getDashboard(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<Dashboard>(
    '/admin/common/dashboard',
    config,
  );

  return response;
}

export function useDashboardQuery(
  data?: IApiUseQueryData<Dashboard, AxiosResponse>,
) {
  return useQuery<AxiosResponse<Dashboard>>(
    [COMMON_QUERY_KEYS.DASHBOARD, data?.requestConfig?.params],
    () => getDashboard(data?.requestConfig),
    data?.queryOptions,
  );
}

/** DASHBOARD GOALS */
export async function getDashboardGoals(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<DashboardGoals>(
    '/admin/common/dashboard/goals',
    config,
  );

  return response;
}

export function useDashboardGoalsQuery(
  data?: IApiUseQueryData<DashboardGoals, AxiosResponse>,
) {
  return useQuery<AxiosResponse<DashboardGoals>>(
    [COMMON_QUERY_KEYS.DASHBOARD, 'goals', data?.requestConfig?.params],
    () => getDashboardGoals(data?.requestConfig),
    data?.queryOptions,
  );
}

/** PERMISSIONS */
export async function getPermissions(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<IPermission>>(
    '/admin/common/permissions',
    config,
  );

  return response;
}

export function usePermissionsQuery(data?: IApiUseQueryData<IPermission>) {
  return useQuery<IApiUseQueryFnData<IPermission>>(
    [COMMON_QUERY_KEYS.PERMISSIONS, data?.requestConfig?.params],
    () => getPermissions(data?.requestConfig),
    data?.queryOptions,
  );
}

/** ROLES */
export async function getRoles(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<IRole>>(
    '/admin/common/roles',
    config,
  );

  return response;
}

export function useRolesQuery(data?: IApiUseQueryData<IRole>) {
  return useQuery<IApiUseQueryFnData<IRole>>(
    [COMMON_QUERY_KEYS.ROLES, data?.requestConfig?.params],
    () => getRoles(data?.requestConfig),
    data?.queryOptions,
  );
}

export async function getRole(
  id: number | string,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IRole>(
    `/admin/common/roles/${id}`,
    config,
  );

  return response;
}

export function useRoleQuery(
  id: number | string,
  data?: IApiUseQueryData<IRole, AxiosResponse>,
) {
  return useQuery<AxiosResponse<IRole>>(
    [COMMON_QUERY_KEYS.ROLES, id, data?.requestConfig?.params],
    () => getRole(id, data?.requestConfig),
    data?.queryOptions,
  );
}

/** USERS */
export async function getUsers(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<IUser>>(
    '/admin/common/users',
    config,
  );

  return response;
}

export function useUsersQuery(data?: IApiUseQueryData<IUser>) {
  return useQuery<IApiUseQueryFnData<IUser>>(
    [COMMON_QUERY_KEYS.USERS, data?.requestConfig?.params],
    () => getUsers(data?.requestConfig),
    data?.queryOptions,
  );
}

/** DEPARTMENTS */
export async function getDepartments(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<IDepartment>>(
    '/admin/common/departments',
    config,
  );

  return response;
}

export function useDepartmentsQuery(data?: IApiUseQueryData<IDepartment>) {
  return useQuery<IApiUseQueryFnData<IDepartment>>(
    [COMMON_QUERY_KEYS.DEPARTMENTS, data?.requestConfig?.params],
    () => getDepartments(data?.requestConfig),
    data?.queryOptions,
  );
}

/** LEADS */
export async function getLeads(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Lead>>(
    '/admin/common/leads',
    config,
  );

  return response;
}

export function useLeadsQuery(data?: IApiUseQueryData<Lead>) {
  return useQuery<IApiUseQueryFnData<Lead>>(
    [COMMON_QUERY_KEYS.LEADS, data?.requestConfig?.params],
    () => getLeads(data?.requestConfig),
    data?.queryOptions,
  );
}
