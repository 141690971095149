import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Can from 'src/components/Can';
import ListItemCollapse from 'src/components/ListItemCollapse';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';

interface IItemProps {
  item: ISideMenuItem;
}

const Item: React.FC<IItemProps> = ({ item }) => {
  const { pathname } = useLocation();

  function isItemSelected(currentItem: ISideMenuItem): boolean {
    if (currentItem.subItems) {
      return currentItem.subItems.some((subItem) => isItemSelected(subItem));
    } else if (currentItem.route) {
      return pathname === currentItem.route;
    }

    return false;
  }

  if (item.subItems) {
    return (
      <ListItemCollapse
        primary={item.primaryText}
        icon={item.icon && <item.icon />}
        collapseProps={{
          in: item.subItems?.some((subItem) => isItemSelected(subItem)),
        }}
      >
        {item.subItems.map((subItem) => (
          <Item key={subItem.primaryText} item={subItem} />
        ))}
      </ListItemCollapse>
    );
  }

  const renderItem = () => (
    <ListItemButton
      key={item.route}
      component={(props) => (
        <RouterLink
          {...props}
          to={{ pathname: item.route, search: item.search }}
        />
      )}
      selected={isItemSelected(item)}
    >
      {item.icon && (
        <ListItemIcon>
          <item.icon />
        </ListItemIcon>
      )}

      <ListItemText primary={item.primaryText} secondary={item.secondaryText} />
    </ListItemButton>
  );

  if (item.permissions) {
    return (
      <Can key={item.primaryText} permissions={item.permissions}>
        {renderItem()}
      </Can>
    );
  }

  return renderItem();
};

export default Item;
