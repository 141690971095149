import { FC, useContext } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuItems from 'src/routes/menu-items';
import PrivateContext from 'src/routes/Private/PrivateContext';

import Item from './Item';
import { Drawer, ToolbarIcon } from './styles';

const SideNavigation: FC = () => {
  const { sideMenuIsOpen, closeSideMenu } = useContext(PrivateContext);

  return (
    <Drawer variant="persistent" open={sideMenuIsOpen}>
      <ToolbarIcon>
        <IconButton onClick={closeSideMenu} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </ToolbarIcon>

      <Divider />

      <List>
        {MenuItems.map((item) => (
          <Item key={item.primaryText} item={item} />
        ))}
      </List>
    </Drawer>
  );
};

export default SideNavigation;
