import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import QrCodeIcon from '@mui/icons-material/QrCode2';
import {
  Card,
  CardActionArea,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import QrCode from 'qrcode';
import VCardsJS from 'rn-vcards-js';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import CustomDialog from 'src/components/CustomDialog';
import { CustomDialogRef } from 'src/components/CustomDialog/interfaces';
import IconButton from 'src/components/IconButton';
import LinkExternal from 'src/components/LinkExternal';
import { formatIntToMoney } from 'src/helpers/number';
import { OpportunityInterest } from 'src/modules/attendance/@types/models';
import { OpportunityDetailsParams } from 'src/modules/attendance/@types/params';
import ChangeStatusOpportunityDialog from 'src/modules/attendance/components/ChangeStatusOpportunityDialog';
import DestroyOpportunityDialog from 'src/modules/attendance/components/DestroyOpportunityDialog';
import OpportunityAttendantsDialog from 'src/modules/attendance/components/OpportunityAttendantsDialog';
import OpportunityTransferFunnelDialog from 'src/modules/attendance/components/OpportunityTransferFunnelDialog';
import StoreOpportunityAttendanceDialog from 'src/modules/attendance/components/StoreOpportunityAttendanceDialog';
import UpdateOpportunityDialog from 'src/modules/attendance/components/UpdateOpportunityDialog';
import { OPPORTUNITY_STATUS_LABEL } from 'src/modules/attendance/constants';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';
import { opportunityStatusTextColor } from 'src/modules/attendance/helpers';
import ProductsList from 'src/modules/attendance/pages/Private/OpportunityDetails/ProductsList';
import attendanceApi from 'src/modules/attendance/services/api';
import { formatDate } from 'src/utils/helpers';
import masks from 'src/utils/masks';

import Loading from './Loading';
import NotFound from './NotFound';
import {
  BodyContainer,
  BodyItem,
  Container,
  Header,
  HeaderText,
} from './styles';

const OpportunityDetails: React.FC = () => {
  const contactDialogRef = useRef<CustomDialogRef>(null);
  const params = useParams<OpportunityDetailsParams>();
  const {
    data: response,
    isLoading,
    refetch,
  } = attendanceApi.admin.funnels.opportunities.useFunnelOpportunityQuery(
    params.funnelId,
    params.id,
  );
  const [openStoreAttendance, setOpenStoreAttendance] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openAttendants, setOpenAttendants] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDestroy, setOpenDestroy] = useState(false);
  const [selectedInterest, setSelectedInterest] =
    useState<OpportunityInterest>();
  const history = useHistory();

  if (isLoading) return <Loading />;

  if (!response?.data) return <NotFound />;

  const { data: opportunity } = response;

  function getStatusText() {
    const statusLabel = opportunity.is_waiting
      ? 'Em Espera até'
      : OPPORTUNITY_STATUS_LABEL[opportunity.status];

    let formattedStatusDate = '';
    if (opportunity.is_waiting) {
      formattedStatusDate = formatDate(
        opportunity.wait_until || '',
        'dd/MM/yyyy HH:mm',
      );
    } else if (opportunity.status === 'open') {
      formattedStatusDate = formatDate(
        opportunity.created_at,
        'dd/MM/yyyy HH:mm',
      );
    } else {
      formattedStatusDate = formatDate(
        opportunity.closed_at || '',
        'dd/MM/yyyy HH:mm',
      );
    }

    return `${statusLabel} - ${formattedStatusDate}`;
  }

  function handleSelectInterest(interest: OpportunityInterest) {
    if (interest.id === selectedInterest?.id) {
      return setSelectedInterest(undefined);
    }
    setSelectedInterest(interest);
  }

  async function getVCardQrCode() {
    const leadVCard = VCardsJS();
    leadVCard.firstName = opportunity.lead?.name;
    leadVCard.email = opportunity.lead?.email;
    leadVCard.cellPhone = [opportunity.lead?.phone];
    if (opportunity.lead?.whatsapp_number) {
      leadVCard.cellPhone.push(opportunity.lead?.whatsapp_number);
    }

    setTimeout(() => {
      const canvas = document.getElementById('contact-qrcode');
      QrCode.toCanvas(canvas, leadVCard.getFormattedString());
    });
  }

  return (
    <Container maxWidth="xl">
      <Header square>
        <Grid container spacing={1}>
          <Grid item>
            <Typography>
              <BackButton />
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h6">
              <strong>
                {opportunity.name ||
                  `Oportunidade de ${opportunity.lead?.name}`}
              </strong>
              <IconButton
                size="small"
                tooltip="Alterar Título"
                color="info"
                onClick={() => setOpenUpdate(true)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <Can
                permissions={[ATTENDANCE_PERMISSIONS.OPPORTUNITIES.DESTROY]}
                behavior="hide"
              >
                <IconButton
                  size="small"
                  tooltip="Excluir Oportunidade"
                  color="error"
                  onClick={() => setOpenDestroy(true)}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Can>
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item sm={6}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sm>
                <HeaderText>
                  Lead:{' '}
                  <LinkExternal
                    href={`https://www.google.com/search?q=${opportunity.lead?.name}`}
                  >
                    <strong>{opportunity.lead?.name}</strong>
                  </LinkExternal>{' '}
                  <IconButton
                    size="small"
                    tooltip="Adicionar Contato"
                    color="info"
                    onClick={() => contactDialogRef.current?.show()}
                  >
                    <QrCodeIcon fontSize="inherit" />
                  </IconButton>
                </HeaderText>
                <HeaderText>
                  E-mail:{' '}
                  <LinkExternal href={`mailto:${opportunity.lead?.email}`}>
                    <strong>{opportunity.lead?.email}</strong>
                  </LinkExternal>
                </HeaderText>
              </Grid>

              <Grid item xs={12} sm>
                {opportunity.lead?.phone && (
                  <HeaderText>
                    Telefone:{' '}
                    <LinkExternal href={`tel:${opportunity.lead.phone}`}>
                      <strong>
                        {masks.strictPhoneOrCellphone(opportunity.lead.phone)}
                      </strong>
                    </LinkExternal>
                  </HeaderText>
                )}
                {opportunity.lead?.whatsapp_number && (
                  <HeaderText>
                    WhatsApp:{' '}
                    <LinkExternal
                      href={`https://wa.me/${opportunity.lead.whatsapp_number}`}
                    >
                      <strong>
                        {masks.strictPhoneOrCellphone(
                          opportunity.lead.whatsapp_number,
                        )}
                      </strong>
                    </LinkExternal>
                  </HeaderText>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={6}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <HeaderText>
                  Status:{' '}
                  <HeaderText
                    display="inline"
                    fontWeight="bold"
                    color={opportunityStatusTextColor(opportunity)}
                    marginRight={1}
                  >
                    {getStatusText()}
                  </HeaderText>
                  <Can
                    permissions={[
                      ATTENDANCE_PERMISSIONS.OPPORTUNITIES.UPDATE_STATUS,
                    ]}
                    behavior="hide"
                  >
                    <IconButton
                      size="small"
                      tooltip="Alterar Status"
                      color="info"
                      onClick={() => setOpenChangeStatus(true)}
                      sx={{ p: 0 }}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </Can>
                </HeaderText>

                <HeaderText>
                  Valor: <strong>{formatIntToMoney(opportunity.amount)}</strong>
                </HeaderText>

                <Grid container alignItems="center" gap={1}>
                  <HeaderText>
                    Atendente: <strong>{opportunity.attendant?.name}</strong>
                  </HeaderText>

                  <IconButton
                    size="small"
                    tooltip="Ver Atendentes"
                    color="info"
                    onClick={() => setOpenAttendants(true)}
                    sx={{ p: 0 }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item>
                <HeaderText>
                  Funil: <strong>{opportunity.step?.funnel?.name}</strong>{' '}
                  <IconButton
                    size="small"
                    tooltip="Alterar Funil"
                    color="info"
                    onClick={() => setOpenTransfer(true)}
                    sx={{ p: 0 }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </HeaderText>

                <HeaderText>
                  Etapa:{' '}
                  <strong>
                    {opportunity.step?.name} -{' '}
                    {formatDate(
                      opportunity.funnel_step_entered_at,
                      'dd/MM/yyyy HH:mm',
                    )}
                  </strong>
                </HeaderText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Header>

      <BodyContainer container>
        <BodyItem item xs={12} sm={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign="center">
              <Button
                color="success"
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => setOpenStoreAttendance(true)}
                disabled={opportunity.status !== 'open'}
              >
                Atendimento
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                {opportunity.interests?.map((interest) => (
                  <Grid key={interest.id} item xs={12}>
                    <Card
                      sx={{
                        backgroundColor: (theme) =>
                          selectedInterest?.id === interest.id
                            ? theme.palette.action.selected
                            : '',
                      }}
                    >
                      <CardActionArea
                        sx={{ p: 1 }}
                        onClick={() => handleSelectInterest(interest)}
                      >
                        <Typography variant="subtitle2" color="GrayText">
                          {formatDate(interest.created_at, 'dd/MM/yyyy HH:mm')}
                          {interest.attendant && (
                            <span> - {interest.attendant?.name}</span>
                          )}
                        </Typography>
                        <Typography variant="body2" whiteSpace="pre-line">
                          {interest.description}
                        </Typography>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </BodyItem>

        <BodyItem item xs={12} sm>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                <Typography variant="h6" align="center">
                  Produtos de Interesses
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <ProductsList
                products={opportunity.products}
                selectedProducts={selectedInterest?.products?.map(
                  (product) => product.id,
                )}
              />
            </Grid>
          </Grid>
        </BodyItem>
      </BodyContainer>

      <CustomDialog
        ref={contactDialogRef}
        title="Adicionar Contato"
        titleProps={{ textAlign: 'center' }}
        afterShow={getVCardQrCode}
        maxWidth="xs"
      >
        <DialogContent style={{ textAlign: 'center' }}>
          <canvas id="contact-qrcode" />
        </DialogContent>
      </CustomDialog>

      <StoreOpportunityAttendanceDialog
        open={openStoreAttendance}
        onClose={() => setOpenStoreAttendance(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
      />

      <ChangeStatusOpportunityDialog
        open={openChangeStatus}
        onClose={() => setOpenChangeStatus(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
      />

      <OpportunityAttendantsDialog
        open={openAttendants}
        onClose={() => setOpenAttendants(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
        onSuccessTransfer={refetch}
      />

      <OpportunityTransferFunnelDialog
        open={openTransfer}
        onClose={() => setOpenTransfer(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
        onSuccess={refetch}
      />

      <UpdateOpportunityDialog
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
        // onSuccess={refetch}
      />

      <DestroyOpportunityDialog
        open={openDestroy}
        onClose={() => setOpenDestroy(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
        onSuccess={history.goBack}
      />
    </Container>
  );
};

export default OpportunityDetails;
