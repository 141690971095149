import yup from 'src/libs/yup';

export const FunnelStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  department_id: yup.number().nullable().label('Departamento'),
  description: yup.string().label('Descrição'),
  reasons: yup.array().of(yup.number()).label('Motivos'),
  attendants: yup.array().of(yup.number()).label('Atendentes'),
});

export const FunnelUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  department_id: yup.number().nullable().label('Departamento'),
  description: yup.string().label('Descrição'),
  reasons: yup.array().of(yup.number()).label('Motivos'),
  attendants: yup.array().of(yup.number()).label('Atendentes'),
});
