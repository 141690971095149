import { FC, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import CssBaseline from '@mui/material/CssBaseline';
import {
  StyledEngineProvider as MuiStyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import ptBRLocale from 'date-fns/locale/pt-BR';
import Toast from 'src/components/Toast';
import { queryClient } from 'src/config/query';
import { useStoreSelector } from 'src/hooks/store';
import Routes from 'src/routes';
import api from 'src/services/api';
import { AppActions } from 'src/store/ducks/app';
import { AuthActions } from 'src/store/ducks/auth';
import { getTheme } from 'src/styles/themes/default/_MuiTheme';
import { handleErrors } from 'src/utils/errors';

const App: FC = () => {
  const { themeMode } = useStoreSelector((state) => state.app);
  const { isAuthenticated } = useStoreSelector((state) => state.auth);
  const dispatch = useDispatch();
  const theme = getTheme(themeMode);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [profileResp, modulesResp] = await Promise.all([
          api.admin.profile.getProfile(),
          api.admin.common.getModules(),
        ]);
        const { data } = profileResp;

        const userPermissions = data.permissions || [];
        const rolePermissions = data.role?.permissions?.filter(
          (rolePerm) =>
            !data.permissions?.find((userPerm) => userPerm.id === rolePerm.id),
        );

        userPermissions.concat(rolePermissions || []);
        dispatch(AuthActions.authProfile(data, userPermissions));
        dispatch(AppActions.appSetModules(modulesResp.data));
      } catch (error) {
        handleErrors(error, 'Erro ao buscar dados do usuário.');
      }
    };

    if (isAuthenticated) loadData();
  }, [isAuthenticated, dispatch]);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap"
        />
      </Helmet>
      <BrowserRouter>
        <MuiStyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <MuiLocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBRLocale}
            >
              <StyledThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                  <CssBaseline />
                  <Routes />
                  <Toast />
                </QueryClientProvider>
              </StyledThemeProvider>
            </MuiLocalizationProvider>
          </MuiThemeProvider>
        </MuiStyledEngineProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
