import { FC } from 'react';

import MenuItem from '@mui/material/MenuItem';

import { CustomMenuItemProps } from './interfaces';

const CustomMenuItem: FC<CustomMenuItemProps> = ({
  Icon,
  text,
  iconProps,
  ...props
}) => {
  return (
    <MenuItem {...props}>
      <Icon
        fontSize="inherit"
        {...iconProps}
        style={{ marginRight: 8, ...iconProps?.style }}
      />
      {text}
    </MenuItem>
  );
};

export default CustomMenuItem;
