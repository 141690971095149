import { createActions, createReducer } from 'reduxsauce';
import { AuthTypes } from 'src/store/ducks/auth';

import {
  IAppCreators,
  IAppSetModules,
  IAppState,
  IAppToggleSideMenu,
  IAppToggleThemeMode,
  IAppTypes,
} from './types';

/* Types & Actions Creators */
export const { Types: AppTypes, Creators: AppActions } = createActions<
  IAppTypes,
  IAppCreators
>({
  appToggleSideMenu: ['isOpen'],
  appToggleThemeMode: ['themeMode'],
  appSetModules: ['modules'],
});

/* Initial State */
const INITIAL_STATE: IAppState = {
  sideMenuIsOpen: false,
  themeMode: 'light',
  modules: [],
};

/* Reducers */
const appToggleSideMenu = (
  state: IAppState,
  { isOpen }: IAppToggleSideMenu,
) => ({
  ...state,
  sideMenuIsOpen: isOpen,
});

const appToggleThemeMode = (
  state: IAppState,
  { themeMode }: IAppToggleThemeMode,
) => ({
  ...state,
  themeMode,
});

const appSetModules = (state: IAppState, { modules }: IAppSetModules) => ({
  ...state,
  modules,
});

const appClean = (state: IAppState) => ({
  ...state,
  modules: [],
});

/* Reducers to types */
const reducer = createReducer(INITIAL_STATE, {
  [AppTypes.APP_TOGGLE_SIDE_MENU]: appToggleSideMenu,
  [AppTypes.APP_TOGGLE_THEME_MODE]: appToggleThemeMode,
  [AppTypes.APP_SET_MODULES]: appSetModules,
  [AuthTypes.AUTH_LOGOUT]: appClean,
});

export default reducer;
